import React from 'react'

// Styles
import './hero.scss'

// Custom component imports
import AnimatedBackgroundGradient from './AnimatedBackgroundGradient'
import AnimatedPetals from './AnimatedPetals'
import BackgroundImage from './BackgroundImage'
import BackgroundOverlay from './BackgroundOverlay'
import Header from './Header'

// svg imports
import wave1 from '../../../assets/waves/wave1.svg'
import wave2 from '../../../assets/waves/wave2.svg'
import wave3 from '../../../assets/waves/wave3.svg'
import wave4 from '../../../assets/waves/wave4.svg'
import wave5 from '../../../assets/waves/wave5.svg'

// Export component
const Hero = () => {
  return (
    <div id="hero">
      <Header />
      <BackgroundOverlay />
      <AnimatedPetals />
      <AnimatedBackgroundGradient />
      <BackgroundImage />
      <div className="wave-container">
        <div className="wave-spacer" />
        <div
          className="wave"
          style={{
            backgroundImage: `url(${wave1})`,
            translate: '0 -50px'
          }}
        />
      </div>
      <div className="wave-container">
        <div className="wave-spacer" />
        <div
          className="wave"
          style={{
            backgroundImage: `url(${wave2})`
          }}
        />
      </div>
      <div className="wave-container">
        <div className="wave-spacer" />
        <div
          className="wave"
          style={{
            backgroundImage: `url(${wave3})`
          }}
        />
      </div>
      <div className="wave-container">
        <div className="wave-spacer" />
        <div
          className="wave"
          style={{
            backgroundImage: `url(${wave4})`
          }}
        />
      </div>
      <div className="wave-container">
        <div className="wave-spacer" />
        <div
          className="wave"
          style={{
            backgroundImage: `url(${wave5})`,
            translate: '0 -42px'
          }}
        />
      </div>
    </div>
  )
}

export default Hero
