import React from 'react'

// Styles
import './header.scss'

// Export component
const Header = () => {
  return (
    <header id="hero-header">
      <div className="avatar avatar-main"></div>
      <div className="avatar avatar-mobile" aria-label="Six Dog House"></div>
      <h1>Six Dog House</h1>
    </header>
  )
}

export default Header
