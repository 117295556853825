import React, { useEffect, useRef } from 'react'

// Styles
import './animatedPetals.scss'

// Export component
const AnimatedPetals = () => {
  return (
    <div id="animated-petals">
      <ul className="petal-set petal-set-one">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <ul className="petal-set petal-set-two">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <ul className="petal-set petal-set-three">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <ul className="petal-set petal-set-four">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <ul className="petal-set petal-set-five">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default AnimatedPetals
