import React from 'react'

// Styles
import './animatedBackgroundGradient.scss'

// Export component
const AnimatedBackgroundGradient = () => {
  return <div id="animated-background-gradient"></div>
}

export default AnimatedBackgroundGradient
