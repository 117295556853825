import React from 'react'
import PropTypes from 'prop-types'

// Styles
import './standardCard.scss'

const StandardCard = ({ children, header }) => {
  return (
    <div className="standard-card">
      <h2>{header}</h2>
      <div className="standard-card-content">{children}</div>
    </div>
  )
}

StandardCard.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string
}

export default StandardCard
