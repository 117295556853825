import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Style imports
import './App.scss'

// Custom component imports
import Home from './pages/Home'

// Export component
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
