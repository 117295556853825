import React from 'react'

// Styles
import './content.scss'

// Custom components
import StandardCard from '../../../components/Cards/StandardCard'

// Export component
const Content = () => {
  return (
    <div id="home-content">
      <div className="content-container limited-width-container-lg">
        {/* <div className="limited-width-container-lg"> */}
        <StandardCard header="Greetings and Salutations">
          <p>
            My name is Eric Van Ness and Six Dog House is where I have fun
            working on personal projects.
          </p>
          <p>
            At the moment my primary focus is the website for{' '}
            <a
              href="https://secretsquirrelrehab.org"
              target="_blank"
              rel="noreferrer"
            >
              Secret Squirrel Wildlife Rehabilitation
            </a>{' '}
            - a non-profit dedicated to rehabilitating wildlife and educating
            about wildlife rehabilitation. At the moment it is a placeholder
            site, but will be a place for reference, information, entertainment,
            and tools for rehabbers.
          </p>
        </StandardCard>
        <StandardCard header="A Little Bit about Six Dog House">
          <p>
            I began Six Dog House many years ago as a project to encourage and
            promote the creation and distribution of intellectual property -
            writing, art, music, etc. Since those early days, it has evolved to
            focus on web development.
          </p>
          <p>
            I decided to call the company SixDogHouse because of my love of and
            for animals, but also because there was a time when mine was indeed
            a six dog house. In actuality, it was a six dog, dozen cat,
            pot-bellied pig, three-horse house, but those are other stories. The
            dogs were Lucky (a black shepherd mix), Cody (a border collie mix),
            Sunshine (a golden retriever), Buttercup (a cinnamon chow chow),
            Roxy (a tri-colored hound), and Willow (a wheaten terrier mix with a
            crazy under-bite). Like every animal I have had the joy to have had
            directly in my life, they were rescues. My passion for animal rescue
            runs deep. So much so that I was tricked into spending 7 years of my
            life working for an animal rescue group – a couple of those as E.D.
            That is what led me on this path to software development.
          </p>
          <p>
            In addition to custom projects, Six Dog House develops free and
            affordable solutions for animal rescue groups and other charitable
            organizations.
          </p>
        </StandardCard>
      </div>
      {/* </div> */}
      <div className="spacer" />
    </div>
  )
}

export default Content
