import React from 'react'

// Styles
import './backgroundOverlay.scss'

// Export component
const BackgroundOverlay = () => {
  return <div id="hero-background-overlay"></div>
}

export default BackgroundOverlay
