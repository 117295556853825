import React from 'react'

// Styles
import './backgroundImage.scss'

// Export component
const BackgroundImage = () => {
  return <div id="hero-background-image"></div>
}

export default BackgroundImage
