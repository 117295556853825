import React from 'react'

// Styles
import './home.scss'

// Custom component imports
import Content from './Content'
import Hero from './Hero'

// Export Component
const Home = () => {
  return (
    <div id="home">
      <Hero />
      <Content />
    </div>
  )
}

export default Home
